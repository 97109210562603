<template>
  <div id="user-gifts" class="" style="margin-top:-10px">

    <div class="alert-primary-side mb-3" v-html="$t('gifts.intro')"/>

    <div class="grid grid-cols-xl-2 gap-10 gifts py-4">
      <div v-for="(gift, index) in gifts.filter(x => !!x.chart)" :key="gift.chartId + index" class="card ">
        <div class="card-body d-flex flex-column">
          <div class="card-title"><h5>{{gift.title}}</h5></div>
          <chart-preview :chart="gift.chart" />

          <div class="mt-3 gift-text-xs">
            <div v-if="gift.recipient" class="mb-3">{{ $t('gifts.received.thanks', { name: gift.recipient?.name }) }}</div>
            <div v-else v-html="$t('gifts.copyLinkExtended')"/>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-auto">
            <b-button @click="copyLink(gift, index)"
                      :variant="gift.received ? 'outline-white' : 'primary'" size="xs"
                      :disabled="gift.received || copied[index]">
                <span v-if="!copied[index]">
                                  {{ $t('gifts.' + (gift.received ? 'giftReceived' : 'copyLink')) }}
                </span>
              <span v-else >
                    <span class="icon-check"/> {{ $t('general.copied') }}
                </span>

            </b-button>
            <b-button variant="link" class="text-primary" @click="$router.push('/charts/'+gift.chartId)">
              {{ $t('gifts.viewChart') }}
            </b-button>
          </div>

        </div>
      </div>
      <div v-if="gifts.length === 0 && !loading">
        <p>{{$t('gifts.noGifts')}}</p>
      </div>
    </div>

  </div>
</template>
<script>

import GiftMixin from "@/mixins/GiftMixin";
import ChartUtil from "@/mixins/ChartUtil";
import ChartPreview from "@/components/library/ChartPreview.vue";
import {mapState} from "vuex";

export default {
  components: {ChartPreview},

  mixins: [ChartUtil, GiftMixin],

  computed: {
    ...mapState({
      chartToken: state => state.account.chartsToken
    })
  },
  data() {
    return {
      gifts: [],
      copied: {},
      loading: false,
    }
  },
  watch: {
    chartToken(){
      this.getPurchases();
    }
  },
  mounted(){
    this.getPurchases();
  },

  methods: {
    getPurchases() {
      if (!this.chartToken) return;
      this.loading = true;
      this.axios.get('/billing/gifts/eligible-unlocks').then((res) => {
        const gifts = this.formatResults(res.data.purchases);
        if (gifts.length === 0) return;

        const chartIds = gifts.map(gift => gift.chartId);
        this.axios.get(`${this.config.chartsUrl}/api/charts/names`, {
          params: { chartIds }
        }).then(res => {
          for (const gift of gifts) {
            gift.chart = res.data.find(x => x.id === gift.chartId)
          }
          this.gifts = gifts.filter(x=> x.chartId !== this.$store.state.account?.user?.chart_id);
        }).catch(() => {
          this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
        });
      }).finally(()=>{
        this.loading = false;
      });
    },
    formatResults(gifts) {
      const availableGifts = [];
      for(const gift of gifts) {
        if (!gift.metadata.cart) continue;
        for(const item of gift.metadata.cart) {

          availableGifts.push({
            title: this.$t('billing.products.names.' + item.product_key),
            text: item.giftedChartId ?  this.$t('gifts.received.thanks', { name: item.recipient?.name }) : this.$t('billing.products.unlocks.' + item.product_key),
            giftId: gift.id,
            chartId: item.chart_id,
            unlockCode: this.getUnlockCode(item.product_key),
            received: !!item.giftedChartId,
            recipient: item.recipient,
            productKey: item.product_key,
            purchase: { id: gift.id }
          });
        }
      }
      return availableGifts;
    }
  }
}
</script>