<template>


  <div id="view-options-wrapper" class="my-md-2">
    <div class="d-flex w-100 flex-wrap justify-content-center justify-content-md-start">
      <div id="chart-mode-options" class="flex-shrink-0 w-md-25 desktop-version">
        <label class="font-weight-light mb-2">Chart Mode</label>
        <div class="d-flex chart-mode-icons">
          <b-button v-b-tooltip.hover :title="$t('charts.tooltips.bodygraph')"
                    :variant="isRegularMode ? 'primary' :'link'" size="xs"
                    @click="setShowMandala(false)"><span class="icon-bodygraph"></span></b-button>

          <b-button v-b-tooltip.hover :title="$t('charts.tooltips.mandala')" :variant="isMandalaMode ? 'primary' :'link'"
                    size="xs" @click="setShowMandala(true)"
          ><span class="icon-bodygraph-outline"/></b-button>
          <b-button v-if="showAiToggle" v-b-tooltip.hover :disabled="!canEnableAi" :title="$t('charts.tooltips.ai')"
                    :variant="aiEnabled ? 'primary' :'link'" size="xs"
                    @click="setShowMandala('ai')"
          ><span class="icon-advanced-analysis"/>
          </b-button>
        </div>

      </div>

      <div id="view-mode-options" class="flex-shrink-0 ml-5 desktop-version">
        <label class="font-weight-light mb-2">View Mode</label>
        <div class="d-flex">

          <b-button v-for="mode in getViewModes" :key="mode" v-b-tooltip.hover :title="getModeTooltip(mode)"
                    :variant="mode === viewMode ? 'primary' : 'link'"
                    size="xs" @click="setViewMode(mode)">
            <div v-html="viewModeIcons[mode]"></div>
          </b-button>

          <!--          <b-button :variant=""-->
          <!--          <b-button :variant="isMandalaEnabled ? 'primary' :'link'" @click="setShowMandala(true)" size="sm"><span class="icon-bodygraph-outline"></span></b-button>-->
        </div>

      </div>

      <div v-if="isTemp && screenWidth > 768" id="save-chart" class="flex-shrink-0 text-right ml-auto d-flex align-items-center">
        <b-button :disabled="isSaving" class="mt-md-4" size="sm" variant="full-white" @click="$emit('saveTempChart')">
          <span class="icon-save mr-1"/> {{ $t('general.saveChart') }}
        </b-button>
      </div>

      <!-- Options toggle -->
      <div v-if="!isTempChart(chart) || (screenWidth < 768)" id="options"
           class="flex-shrink-0 text-center text-md-right justify-content-end justify-content-md-end m-0 mr-md-0 ml-auto">

        <b-dropdown id="other-options" :text="$t('charts.properties.otherOptions')"
                    class="mr-md-0 text-sm btn btn-sm mt-md-3 "
                    style="padding-right:0!important"
                    right
                    variant="full-white">


          <b-dropdown-item v-if="isTemp" class="mobile-version" @click="$emit('saveTempChart')">
            <span class="icon-save"/> {{ $t('general.saveChart') }}
          </b-dropdown-item>

          <b-dropdown-divider v-if="isTemp" class="mobile-version"/>

          <b-dropdown-item :active="isRegularMode" class="mobile-version" @click="setShowMandala(false)">
            <span class="icon-bodygraph"/> {{ $t('charts.tooltips.bodygraph') }}
          </b-dropdown-item>

          <b-dropdown-item :active="isMandalaMode" class="mobile-version" @click="setShowMandala(true)">
            <span class="icon-bodygraph-outline"/> {{ $t('charts.tooltips.mandala') }}
          </b-dropdown-item>

          <b-dropdown-item :active="aiEnabled" class="mobile-version" @click="setShowMandala('ai')" :disabled="!canEnableAi">
            <span class="icon-advanced-analysis"/> {{ $t('charts.tooltips.ai') }}
          </b-dropdown-item>

          <b-dropdown-divider class="mobile-version"/>

          <b-dropdown-item v-for="mode in getViewModes" :key="mode" :active="mode === viewMode"
                           class="mobile-version"
                           @click="setViewMode(mode)"
          >
            <div class="d-flex align-items-center gap-10 view-modes-icons">
              <div v-html="viewModeIcons[mode]"/>
              {{ getModeTooltip(mode) }}
            </div>

          </b-dropdown-item>
          <b-dropdown-divider class="mobile-version"/>

          <b-dropdown-item
              v-if="(isPersonalChart(chart) || isConnectionChart(chart)) && !chart.isCelebrity
              && chart.id !== $store.state.account?.user?.chart_id"
              link-class="text-primary"
              @click="$store.commit('ui/SET_SHOW_GIFT_CHART', true)"
          ><span class="icon-gift text-sm"/> {{ $t('gifts.title') }}
          </b-dropdown-item>

          <b-dropdown-item
              v-if="!isTransitChart(chart)"
              @click="$store.commit('ui/SET_SHOW_SHARE_CHART', true)"
          ><span class="icon-share text-sm"/> {{ $t('charts.properties.share') }}
          </b-dropdown-item>

          <b-dropdown-item @click="$store.commit('ui/SET_SHOW_EDIT_CHART', true)" :disabled="chart.isCelebrity"><span
              class="icon-edit text-sm"/>
            {{ $t('charts.properties.edit') }}
          </b-dropdown-item>

          <b-dropdown-item @click="showNotes">
            <span class="icon-notes text-sm"/>
            {{ $t('charts.properties.notes') }}
          </b-dropdown-item>

          <b-dropdown-item v-if="isPersonalChart(chart)" @click="$emit('showJustNowConnection', chart)">
            <span class="icon-transit text-sm"/>
            {{ $t('charts.properties.viewJustNowConnection') }}
          </b-dropdown-item>

          <b-dropdown-item v-if="!isTempChart(chart)" @click="$store.commit('ui/SET_SHOW_DOWNLOAD_IMAGE', true)"><span
              class="icon-save text-sm"/>
            {{ $t('charts.properties.image') }}
          </b-dropdown-item>

          <b-dropdown-item v-if="chart.isCelebrity" @click="saveCelebrityChart"><span
              class="icon-download text-sm"/>
            {{ $t('charts.properties.copyChart') }}
          </b-dropdown-item>

          <b-dropdown-divider v-if="showAdminButtons" />
          <b-dropdown-item v-if="showAdminButtons" @click="switchCelebrityStatus(chart)"><span
              class="icon-admin text-sm"/>
            {{ chart.isCelebrity ? 'Remove from Celebrity Charts' : 'Convert to Celebrity Chart' }}
          </b-dropdown-item>

        </b-dropdown>
      </div>
    </div>
  </div>


</template>

<script>
import viewModeIcons from "@/assets/icons/view-modes";
import {mapState} from "vuex";
import ChartUtil from "@/mixins/ChartUtil";
import {getLocalStorageItem, localStorageKeys, setLocalStorageItem} from "@/utils/localStorage";
import ChartPageMixin from "@/mixins/ChartPageMixin";
import ScreenSizeMixin from "@/mixins/ScreenSizeMixin";

export default {
  components: {},

  mixins: [ChartUtil, ChartPageMixin, ScreenSizeMixin],
  props: {
    isMandalaEnabled: {
      type: Boolean,
      default: false
    },
    chartMeta: {
      type: Object,
      default: () => {
      }
    },
    isTemp: {
      type: Boolean,
      default: false
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    chart: {
      type: Object,
      default: () => {
      }
    }
  },

  mounted(){
    this.prepareFromLocalState()
  },

  computed: {
    ...mapState({
      viewModes: state => state.ui.viewModes,
      viewMode: state => state.ui.viewMode,
      aiEnabled: state => state.ui.singleChart.aiEnabled,
      showAdminButtons: state => state.ui.enableAdminButtons,
      subscriptions: state => state.account.subscriptions,
      unlocks: state => state.account.unlockedCharts
    }),


    canEnableAi() {
      const subs = this.subscriptions.map(x => x.products.map(y => y.key)).flat();
      return subs.includes('advanced') || !!this.unlocks.find(x => x.chart_id === this.$store.state.charts.chart.id && x.purchase_type === 'advanced')
    },

    isRegularMode() {
      return !this.isMandalaEnabled && !this.aiEnabled;
    },

    isMandalaMode() {
      return this.isMandalaEnabled && !this.aiEnabled
    },

    showAiToggle() {
      return this.isPersonalChart(this.chartMeta.type)
    },

    getViewModes() {
      if (this.isTransitChart(this.chartMeta.type) && !this.isPersonTransitChart({meta: this.chartMeta}))
        return Object.values(this.viewModes).filter(x => x !== this.viewModes.design && x !== this.viewModes.personality);
      return this.viewModes;
    },
    localStateKey(){
      return this.chartMeta.type+ '_' + (this.isPersonTransitChart({meta: this.chartMeta}) ? 1 : 0)
    },
  },
  data() {
    return {
      viewModeIcons
    }
  },

  // reset view modes (mandala, etc) when leaving.
  async destroyed() {
    this.setViewMode(this.viewModes.normal, false);
    this.setShowMandala(false, false);
  },

  methods: {

    prepareFromLocalState(){
      const localChartModeState = getLocalStorageItem(localStorageKeys.CHART_MODE);
      if (localChartModeState && localChartModeState[this.localStateKey]) {
        const data = localChartModeState[this.localStateKey];
        if(this.canEnableAi) this.$store.commit('ui/SET_AI_ENABLED', !!data.isAi);
        this.$store.commit('ui/SET_SHOW_MANDALA', !!data.isMandala);
      }

      const localChartViewModeState = getLocalStorageItem(localStorageKeys.CHART_VIEW_MODE);
      if (localChartViewModeState && localChartViewModeState[this.localStateKey] ) {
        this.setViewMode(localChartViewModeState[this.localStateKey]);
      }
    },
    toggleLocalState({
        type = 'chart_mode',
        isMandala = false,
        isAi = false,
        viewMode
    }){
      if(type === 'chart_mode') {
        setLocalStorageItem(localStorageKeys.CHART_MODE, {
          ...getLocalStorageItem(localStorageKeys.CHART_MODE),
          [this.localStateKey]: {
            isMandala,
            isAi
          }
        }, true);
      }

      if(type === 'view_mode') {
        setLocalStorageItem(localStorageKeys.CHART_VIEW_MODE, {
          ...getLocalStorageItem(localStorageKeys.CHART_VIEW_MODE),
          [this.localStateKey]: viewMode
        }, true)
      }
    },
    setShowMandala(val, saveState = true) {
      if (val === 'ai') {
        this.$store.commit('ui/SET_AI_ENABLED', true);
        this.$store.commit('ui/SET_SHOW_MANDALA', false);
        if(saveState) this.toggleLocalState({ isAi: true, isMandala: false });
      } else {
        this.$store.commit('ui/SET_AI_ENABLED', false);
        this.$store.commit('ui/SET_SHOW_MANDALA', val);
        if(saveState) this.toggleLocalState({ isAi: false, isMandala: val });
      }
    },
    setViewMode(val, saveToState = true) {
      if (val === this.viewModes.cross) {
        this.setShowMandala(true);
      } else {
        // if(this.isMandalaMode) this.setShowMandala(false);
      }
      this.$store.commit('ui/SET_VIEW_MODE', val);
      if(saveToState) this.toggleLocalState({
        type: 'view_mode',
        viewMode: val
      });
    },
    getModeTooltip(mode) {
      if (mode === this.viewModes.design || mode === this.viewModes.personality) {

        if (this.isConnectionChart(this.chartMeta.type)) {
          return this.$t('charts.tooltips.' + mode + '.connection', {
            person1: this.chartMeta.person1.name,
            person2: this.chartMeta.person2.name
          })
        }

        if (this.isPersonTransitChart({meta: this.chartMeta})) {
          return this.$t('charts.tooltips.' + mode + '.connection', {
            person1: this.chartMeta.person.name,
            person2: this.$t('hd.properties.transit.title')
          })
        }

        if (this.isCycleChart(this.chartMeta.type)) {
          return this.$t('charts.tooltips.' + mode + '.connection', {
            person1: this.chartMeta.person.name,
            person2: this.$t('hd.properties.cycles.title')
          })
        }

        return this.$t('charts.tooltips.' + mode + '.rave');

      }
      return this.$t('charts.tooltips.' + mode);
    },


    showNotes() {
      this.$store.commit('ui/SET_STARING_OWL_TAB', 'notes')
      this.$store.commit('ui/SET_SHOW_OWL', true);
    },

    saveCelebrityChart(){
      this.duplicateDoc(this.chart.id).then(chart =>{
        this.redirectTo('single-chart', null, {chartId: chart.id});
      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      });
    },

    switchCelebrityStatus(chart) {
      this.axios.post('/admin/general/chart-celebrity-status', {
        chartId: chart.id,
        enable: !chart.isCelebrity,
      }).then(() => {
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.updated')});
        this.$emit('refetch');
      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      });
    }
  }
}
</script>

<style lang="scss">
.chart-mode-icons {
  button {
    display: flex;
  }

  [class^=icon-] {
    padding: 2px;
    font-size: 20px !important;
  }
}

#options {
  svg {
    max-width: 16px;
  }
}
</style>