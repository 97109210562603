<template>
  <div class="mt-3 mb-5">
    <b-alert v-if="chart?.meta?.is_locked" show variant="warning" class="mt-3">
      <p v-if="!isMainChart" class="mb-0" >
        <span class="font-weight-bold">USE WITH CAUTION: </span> This chart is locked (has unlocked analysis)
        and cannot be deleted by the user. You can delete it as an admin, which will end up
        deleting both the chart and the analysis entries.
      </p>
      <p v-else class="mb-0 ">
        You cannot delete the user's main chart.
      </p>
    </b-alert>

    <label>Admin unlocks</label>
    <div v-if="chart?.meta?.type === 'RAVE_CHART'" class="d-flex mt-2 gap-10">
      <b-button v-if="status && !status.foundational" variant="outline-primary" @click="adminUnlockChart('foundational')">
        Unlock foundational analysis
      </b-button>
      <b-button v-if="status && !status.advanced" variant="outline-primary" @click="adminUnlockChart('advanced')">
        Unlock advanced analysis
      </b-button>
    </div>
    <div v-if="chart?.meta?.type === 'CONNECTION_CHART'" class="mt-2">
      <b-button v-if="status && !status?.is_unlocked" variant="outline-primary" @click="adminUnlockChart('companionship')">
        Unlock Partnership Analysis
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chart: Object,
    validatedAdminData: Function,
    isMainChart: Boolean
  },
  data(){
    return {
      status: null
    }
  },
  mounted() {
    this.fetchChartStatus();
  },
  methods: {

    // fetches a chart's status from the API.
    // if it's a rave chart, we retrieve the {foundation: boolean, advanced: boolean}
    // Otherwise we retrieve {is_unlocked:boolean}
    fetchChartStatus() {
      // we only care about status in this state, only if we're admins.
      if (!this.chart.id) return;
      this.axios.get(`/content/${this.chart.id}/status`, {
        params: {
          type: this.chart.meta?.type
        }
      }).then(res => {
        this.status = res.data;
      }).catch(() => {
        return null;
      });
    },

    adminUnlockChart(type) {
      const data = this.validatedAdminData(this.chart.id);
      this.axios.post(`/admin/users/${data.user.id}/unlock-chart/${this.chart.id}`, {
        purchase_type: type
      }, {
        headers: {
          'x-access-token': data.adminToken.replace('#', ''),
          'skip-interceptors': true,
        }
      }).then(() => {
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.text')});
        this.$store.dispatch('account/getUserUnlockedCharts');
        this.fetchChartStatus();
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      });
    },
  }
}
</script>