const {config} = require("../utils/config");
const setupAxiosInterceptors = (store, router, axiosInstance) => {
    // create an interceptor to add token to requests towards the API.
    axiosInstance.interceptors.request.use(config => {
        const token = store.state.account.token;
        const chartsToken = store.state.account.chartsToken;

        config.headers['x-application'] = 'mbg'; // add application header to all requests

        // skip interceptors for certain requests
        if (config.headers['skip-interceptors']) return config;

        // add or remove token from requests based on state
        if(token) config.headers['x-access-token'] = token
        else delete config.headers['x-access-token'] // remove token from default headers if we are logged out or no token exists in state

        // add or remove chart microservice token based on state
        if(chartsToken) config.headers['x-charts-token'] = chartsToken
        else delete config.headers['x-charts-token']

        return config;
    }, err => {
        return Promise.reject(err);
    });

    axiosInstance.interceptors.response.use(response => {
        return response;
    }, err => {
        if(err.response && err.response.status === 401){
            // if it's an external call to the charts microservice, we want to re-fetch an access token.
            if(err.response.config.url){
                if(err.response.config.url.includes(config.chartsUrl)){
                    return store.dispatch('account/getChartsMicroserviceToken');
                }
            }
            return store.dispatch('account/logout').then(()=>{
                router.push({name: 'login'});
            });
        }else{
            return Promise.reject(err);
        }

    });
}
module.exports = {
    setupAxiosInterceptors
}