export default{
    title: 'Your account',
    settings: {
        openChart: 'Open Your Chart',
        title: 'Settings',
        subtitle: 'Account information',
        name: 'Name',
        email: 'Email address',
        password: 'Password',
        currentPassword: 'Current password',
        newPassword:'New password',
        repeatNewPassword: 'Repeat new password',
        newsletter:{
            title: 'Do you like updates?',
            signUp: 'Yes, sign me up',
            paragraph: 'We will send you occasional myBodyGraph offers and news about our other official Human Design sites, including Jovian Archive, Ra.TV and Maia Mechanics.'
        },
        deleteAccount: 'Delete Account',
        areYouSure: 'Are you sure you want to delete your account? All your data will be permanently deleted and become unrecoverable.',
        typePassword: 'Please type in your password to confirm the deletion.'
    },
    billing:{
        title: 'Billing',
        subtitle: 'Purchase information',
        help: {
            title: 'Need help?',
            subtitle: 'Visit our help center',
        },
    },
    birthInfo: {
        title: 'Birth Info'
    },
    affiliate: {
        title: 'Referral Program',
        subtitle: 'Become an affiliate'
    }
}