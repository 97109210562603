import {config} from "@/utils/config";

export default{


  data(){
    return{
      docUrl: config.chartsUrl  + '/api/charts',
      sharedDocUrl: config.chartsUrl  + '/api/charts/public',
    }
  },
  methods:{

      async fetchDocNames(docIds) {
            return this.axios.get(`${this.docUrl}/names`, {
                params: {
                    chartIds: [...docIds],
                }
            }).then(res => {
                return res.data;
            });
      },

      async fetchDoc(docId, shared = false){
          const url = (shared ? this.sharedDocUrl : this.docUrl) + '/' + docId;

          return this.axios.get(url).then(res=>{
              return res.data;
          }).catch(e=>{
              this.$router.replace('/404');
              return e;
          })
      },
      duplicateDoc(docId) {
          return this.axios.post(`${this.docUrl}/${docId}/duplicate`).then(res => {
              return res.data;
          });
      },
      async deleteDoc(docId){
          return this.axios.delete(`${this.docUrl}/${docId}`);
      },

      async deleteMultiple(chartIds) {
          return this.axios.post(`${this.docUrl}/multiple/delete`, {chartIds});
      },

      async updateDoc(docId) {
          return this.axios.put(docId)
      },

      updateChartField(chartId, body, updateTags = false){
          // ignore updates on temp docs
          if(chartId.includes('temp')){
              return; // for now
          }

          this.axios.put(`${this.docUrl}/${chartId}`, body).then(res=> {
              this.$notify({type: 'success',
                  title: this.$t('success.title'),
                  text: this.$t('success.updated')});


              this.$store.commit('charts/SET_CHART_META', {
                  meta: res.data.chart.meta,
                  tags: res.data.chart.tags
              })

              if(res.data.newTags.length > 0){
                  this.$store.commit('account/ADD_TAGS', res.data.newTags);
              }
              if (updateTags) {
                  this.setChartTags();
                  // re-cache user tags
                  this.$store.dispatch('account/getUserTags');
              }

          }).catch(()=>{
              this.$notify({type: 'error',
                  title: this.$t('errors.general'),
                  text: this.$t('errors.retry')});
          })
      },
  }
}