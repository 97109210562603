<template>
  <div id="app">
    <global-loader/>
    <component :is="layout" :class="darkMode ? 'theme--dark' : 'theme--light'">
      <router-view></router-view>
    </component>

    <notifications position="bottom center"/>

    <import-charts />
    <owl v-if="isLoggedIn"></owl>

    <admin-impersonation v-if="isAdminImpersonation"/>

    <TransitNotificationPanel />
  </div>
</template>

<script>
import defaultLayout from "./layouts/default"
import authLayout from "./layouts/auth"
import {mapState} from "vuex";
import Owl from "./components/structure/owl/Owl.vue";
import {getLocalStorageItem, localStorageKeys} from "./utils/localStorage";
import AdminImpersonation from "./components/structure/AdminImpersonation.vue";
import TransitNotificationPanel from "./components/charts/transit-tool/TransitNotificationPanel.vue";
import GlobalLoader from "./components/global/GlobalLoader.vue";
import ImportCharts from "@/components/structure/chart-sidebars/ImportCharts.vue";
export default {
  name: 'App',
  components: {
    ImportCharts,
    GlobalLoader,
    TransitNotificationPanel,
    AdminImpersonation,
    Owl,
    defaultLayout,
    authLayout,
  },

  computed:{
    ...mapState({
      isLoggedIn: state => state.account.isLoggedIn,
      isAdminImpersonation: state => state.ui.isAdminImpersonation
    }),
    layout(){
      const layout = this.$route.meta.layout || this.defaultLayout;
      return () => import(`@/layouts/${layout}.vue`)
    }
  },
  watch:{
    isLoggedIn(){
      this.$store.dispatch('account/getUserUnlockedCharts');
      this.$store.dispatch('account/getUserSubscriptions');
      this.$store.dispatch('account/getSettings');
      this.$store.dispatch('account/updatePromoter');
    }
  },
  async created() {
    this.updateFirstPromoterCookie();
    this.updateAdminButtonsState();
    if (getLocalStorageItem(localStorageKeys.ACTIVATE_ACCOUNT)) this.activate();

    if (sessionStorage.getItem(localStorageKeys.IMPERSONATION)) {
      const impersonation = JSON.parse(sessionStorage.getItem(localStorageKeys.IMPERSONATION));
      this.$store.commit('ui/SET_IS_ADMIN_IMPERSONATION', true);
      this.$store.commit('ui/SET_IMPERSONATION_DATA', impersonation)

      if(this.isLoggedIn) await this.$store.dispatch('account/logout');

      await this.$store.dispatch('account/login', impersonation);
      return this.$router.push({name: 'home'});
    }
    this.getNotifications();
  },
  data(){
    return{
      defaultLayout: 'default'
    }
  },
  methods: {
    activate() {
      this.axios.post('/users/activate-email', {
        hash: getLocalStorageItem(localStorageKeys.ACTIVATE_ACCOUNT)
      }).then(() => {
        this.$notify({
              type: 'success',
              title: this.$t('success.title'),
              text: 'Email activated successfully!'
            }
        );
        localStorage.removeItem(localStorageKeys.ACTIVATE_ACCOUNT);
      }).catch(()=> {});
    },
    
    getNotifications() {
      this.axios.get('/content/notifications').then(res => {
        this.$store.commit('account/SET_NOTIFICATIONS', res.data);
      })
    },

    updateFirstPromoterCookie() {
      const firstPromoterQuery = new URLSearchParams(window.location.search).get('ref');
      if (!firstPromoterQuery) return;
      const url = window.location.hostname.split('.').slice(-2).join('.');
      const date = new Date();
      date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));

      document.cookie = `mbg_first_promoter=${firstPromoterQuery}; domain=.${url}; expires="${date.toUTCString()}"; path=/`;
    },

    updateAdminButtonsState() {
      if (sessionStorage.getItem('enable_admin_buttons')) {
        this.$store.commit('ui/SET_ENABLE_ADMIN_BUTTONS', true);
        return;
      }
      const adminQuery = new URLSearchParams(window.location.search).get('enable_admin_buttons');
      if (!adminQuery) return;

      sessionStorage.setItem('enable_admin_buttons', adminQuery);
      this.$store.commit('ui/SET_ENABLE_ADMIN_BUTTONS', true);
    }
  }
}
</script>

<style lang="scss">
@import "./assets/styles/globals/globals";
@import "./assets/styles/layouts/layouts";
#app {
  min-height:100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .router-view-area{
    min-height:70vh;
  }
}
</style>
