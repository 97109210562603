export default{
    login: {
        title: 'Login',
        text: '<strong>If you are an existing user logging in to the new myBodyGraph for the first time</strong>: please log in with your usual email address and password. Your purchased products, chart library, tags and notes will automatically start loading—this may take a few moments.',
        email: 'Type your e-mail',
        password: 'Type your password',
        newUser: 'Are you new to myBodyGraph?',
        registerHere:'Click here to register',
        login: 'Login'
    },
    register: {
        title: 'Register',
        text: 'Create your account and get started.',
        newsletter: 'I want to occasionally receive emails with myBodyGraph offers and news about our other official Human Design sites. (You can unsubscribe at anytime.)',
    },
    accountChart: {
        title: 'Create your personal chart',
        text: 'Almost done! Enter your birth data to generate your Human Design Chart — this will help us personalize your account—and please make sure your birth time is as accurate as possible.',
        chartPairedTitle: 'The chart was successfully paired.',
        chartPairedText: 'You can now access myBodyGraph!'
    },
    appearance: 'Appearance',
    menu: {
        search: 'Search',
        searchPlaceholder: 'Search library',
        home: 'Home',
        library: 'Library',
        new: 'New',
        dark: 'Dark',
        light: 'Light',
        mode: 'Mode',
        shop: 'Shop',
        learn: 'Learn',
        chart: 'Your chart',
        analysis: 'Analysis',
        justNow: 'Just Now Connection',
        help: 'Help'
    }
}