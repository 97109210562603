<template>
  <div id="single-chart" :class="`${chart && chart.meta ? chart.meta.type : ''} ${showProVersionMode ? 'pro-version' : ''}`" >
    <div v-if="chart && chart.chart" class="row mx-0">
      <div class="col-12 col-xl-6 mt-2 mt-md-0 px-md-1 px-1">
        <div id="chart-settings-part" class="row align-items-center align-items-md-start mb-3 my-md-0 pr-0 ">
          <div class="col-0 col-md-7 px-0 desktop-version">
            <p class="mb-0 ">
              <b-badge v-if="chart.isCelebrity" class="bg-purple text-xl">
                <span class="icon-star"/>
                {{$t('library.celebrity.one')}}
              </b-badge>
            </p>

            <h1 class="mb-0">{{ chart.meta.name }}
            </h1>
          </div>
          <div class="col-6 col-md-5 text-md-right p-0">
            <b-button v-if="!showProVersionMode" id="show-chart-properties" variant="full-white" @click="toggleProperties">
              <span :class="`icon-${showProperties ? 'close' : 'info-outline'}`" class="mr-3"></span>
              {{ showProperties ? $t('general.close') : '' }} {{ $t('charts.properties.button') }}
            </b-button>

          </div>
          <div class="col-6 col-md-12 mt-0 mx-0 px-0 text-right text-md-left">
            <view-mode-options
                v-if="chartLoaded"
                :chart-meta="chart.meta"
                :is-mandala-enabled="isMandalaEnabled"
                :is-temp="isTempDoc"
                :is-saving="saving"
                :chart="chart"
                @showJustNowConnection="showJustNowConnection"
                @saveTempChart="save"
                @refetch="fetchChart"
            />
          </div>
        </div>
        <rave-chart v-if="chartLoaded" :key="$store.state.charts.chartKey" :chart="chart" :chart-loaded="chartLoaded"/>
      </div>
      <div id="analysis-part" v-if="chartLoaded && !showProVersionMode" class="col-12 col-xl-6 pl-xl-4 pr-xl-0 pt-4 pt-md-0"
      :class="isTransitChart(chart) ? 'transit-analysis-part' : ''">
        <connection-analysis v-if="isConnectionChart(chart)"/>
        <cycle-analysis v-if="isCycleChart(chart)"/>
        <analysis v-if="!isCycleChart(chart) && !isConnectionChart(chart) && !isTransitChart(chart)"/>
        <transit-tool v-if="isTransitChart(chart)"/>
        <properties/>
      </div>
      <ProVersionSidebar v-else-if="chartLoaded" :chart="chart" class="col-12 col-xl-6 pl-xl-4 pr-xl-0 pt-4 pt-md-0"/>

      <ShareChart v-if="!isTempDoc"
                  :chart-id="docId" :chart-name="chart?.meta.name"/>

      <GiftChart v-if="!isTempDoc && (isPersonalChart(chart) || isConnectionChart(chart))"
                 :chart-id="docId"
                 :personal="isPersonalChart(chart)"
                 :name="chart?.meta.name"
      />

      <EditChart v-if="!isTempDoc"/>

      <DownloadImage v-if="!isTempDoc && chart" :chart-name="chart.meta.name" :chart="chart"/>
    </div>

  </div>

</template>

<style lang="scss">
#analysis-part{
  @media(max-width:767px){
    padding: 10px!important;
    min-height:400px;
  }
  @media(min-width:767px){
    padding: 20px!important;
  }

  &.transit-analysis-part{
    @media(max-width:767px){
      margin-top:40px;
    }
  }
}

#single-chart {

  &.CONNECTION_CHART .not-mandala-wrapper.view-mode-cross {
    .planet-tables-wrapper{
      @media(max-width:540px){
        max-height:115px!important;
        overflow:hidden;
      }
    }
  }
  #chart-settings-part{
    padding: 10px 5px 5px;
    margin:0!important;
    @media(min-width:767px){
      padding:15px;
    }
    @media(min-width:1240px){
      padding-left:25px;
      padding-top: 20px;
      padding-bottom:15px;
    }

  }
  #rave-chart-view-wrapper{
    position: sticky;
    top: 50px;
    @media(min-width:1240px){
      padding-left:25px;
    }
  }

  .chart-wrap {
    position: sticky;
    top: 120px;
    overflow-x: hidden;

    > div {
      padding: 2px;
    }
    align-items: center;
    @media(max-width:640px){
      padding: 0 12px;
    }
  }

  #show-chart-properties {
    transition: .5s all ease-in-out;
  }
}


</style>

<script>

import {mapState} from "vuex";
import ChartPageMixin from "../../mixins/ChartPageMixin";
import properties from "../../components/charts/properties/properties";
import analysis from "../../components/charts/analysis/analysis";
import ViewModeOptions from "../../components/charts/ViewModeOptions";
import RaveChart from "../../components/charts/rendering/RaveChart";
import ConnectionAnalysis from "../../components/charts/analysis-connection/connection-analysis";
import ChartUtil from "../../mixins/ChartUtil";
import TransitTool from "../../components/charts/transit-tool/transit-tool";
import ShareChart from "../../components/structure/chart-sidebars/ShareChart.vue";
import EditChart from "../../components/structure/chart-sidebars/EditChart.vue";
import ChartProgressMixin from "../../mixins/ChartProgressMixin";
import CycleAnalysis from "../../components/charts/analysis/cycle/cycle-analysis.vue";
import ChartCreationMixin from "../../mixins/ChartCreationMixin";
import moment from "moment";
import TransitChartMixin from "../../mixins/TransitChartMixin";
import ProVersionSidebar from "../../components/charts/pro-version/ProVersionSidebar.vue";
import DownloadImage from "../../components/structure/chart-sidebars/DownloadImage.vue";
import GiftChart from "@/components/structure/chart-sidebars/GiftChart.vue";

export default {
  components: {
    GiftChart,
    ProVersionSidebar,
    CycleAnalysis,
    EditChart,
    ShareChart,
    TransitTool,
    ConnectionAnalysis,
    RaveChart,
    ViewModeOptions, properties, analysis,
    DownloadImage
  },

  mixins: [ChartPageMixin, ChartUtil, ChartProgressMixin, ChartCreationMixin, TransitChartMixin],
  computed: {
    ...mapState({
      chart: state => state.charts.chart,
      showProperties: state => state.ui.showProperties,
      showMandala: state => state.ui.showMandala,
      aiEnabled: state => state.ui.singleChart.aiEnabled,
      viewMode: state => state.ui.viewMode,
      viewModes: state => state.ui.viewModes,
      isProVersion: state => !!state.account.settings.pro_sidebar_version,
      user: state => state.account.user,
    }),

    showProVersionMode(){
      return this.isProVersion && this.isPersonalChart(this.chart)
    },

    isMandalaEnabled() {
      return this.showMandala
    },

    isJustNow () {

      const isTransitQuery = this.$route.query && this.$route.query.transit === 'just-now';

      const isJustNowPage = this.$route.path.includes('/transit/just-now');

      // const isJustNowPage = this.$route.params.includes('just-now');
      return isTransitQuery || isJustNowPage
    },

    isTempDoc() {
      return this.$route.name === 'temp-transit-chart' || this.$route.name === 'temp-transit-connection-chart' || this.docId.startsWith('temp-') ;
    },

    isUserChart(){
      return this.$route.name === 'user-chart';
    },

    isTransitDoc() {
      return this.$route.query.transit || this.$route.params.transit;
    }
  },
  data() {
    return {
      docId: '',
      doc: null,
      chartLoaded: false,
      saving: false,
      key: 0,
    }
  },
  watch: {
    chart(chart){
      if(chart?.meta?.name){
        this.$store.dispatch('ui/setTitle', {
          text: chart.meta.name,
          showCelebrity: !!chart.isCelebrity
        })
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('ui/RESET_VIEW'); // reset view modes.
  },

  created() {
    if (this.isUserChart) return this.$router.push({
      path: `/charts/${this.user.chart_id}`,
      query: {...this.$route.query}
    });
    this.$store.commit('ui/RESET_VIEW'); // reset view modes.
    this.$store.commit('charts/RESET_TRANSIT');
    this.$store.commit('charts/RESET_PROGRESS');

    if (this.isTransitDoc && this.isJustNow) {
      this.$store.commit('charts/SET_TRANSIT_JUST_NOW', true);
    }

    // Open edit sidebar if query is set.
    if(this.$route.query.edit_chart){
      this.$store.commit('ui/SET_SHOW_EDIT_CHART', true);
    }

    this.docId = this.$route.params.chartId || 'temp-doc';
    // fetch chart if we do not see a temp one!
    // Also, set it as the last visited one to help us in various places.
    if (!this.isTempDoc && !this.isUserChart) {
      this.$store.commit('ui/SET_LAST_VISITED_CHART', this.docId);
      this.fetchChart();
    }

    // show properties panel if we have tab url selected
    if (new URLSearchParams(window.location.search).has('tab')) this.$store.commit('ui/SET_SHOW_PROPERTIES', true);

    // handle temp docs / transit docs handling
    if (this.docId.includes('temp-') || this.docId.includes('transit?date=')) {

      if(this.isTransitDoc && this.isTempDoc && !this.$route?.query.pre_fetched) return this.handleTempTransitFetch();
      if (this.$store.state.charts.chart.meta) {
        this.chartLoaded = true;

        if(!this.isTempDoc) this.fetchChartStatus(this.$store.state.charts.chart.meta.type)
      } else {
        this.$router.push({name: 'library'});
      }
    }
  },
  methods: {

    // Helps handle URL navigation feature to maintain transit after refreshes (and refresh it) as temp
    async handleTempTransitFetch() {

      const chart = this.$route.query.chart || this.$route.params.transitChartId;

      let transitChart = undefined;

      if(chart){
        const doc = await this.fetchDoc(chart);

        if(doc && doc.meta && this.isPersonalChart(doc)) {
          transitChart = {
            name: doc.meta.name,
            ...doc
          };
        }
      }

      await this.createTransitWrapper({
        dateStamp: moment(),
        timeStamp: moment(),
        chart: transitChart,
        skipNavigation: true
      });

      this.$nextTick(()=>{
        this.$store.dispatch('ui/increasePropertiesKey'); // increase key to update properties
        this.chartLoaded = true;
      })

    },
    toggleProperties() {
      this.$store.commit('ui/SET_SHOW_PROPERTIES', !this.showProperties);
    },
    fetchChart() {
      this.fetchDoc(this.docId).then(chart => {

        if (!chart) {
          this.$router.replace('/404');
          return;
        }

        this.$store.dispatch('charts/setChart', chart);
        this.$store.dispatch('ui/increasePropertiesKey'); // increase key to update properties
        this.chartLoaded = true;
        this.fetchChartStatus(chart.meta.type)
        return this.getChartProgress(this.docId);
      }).catch(e =>{
        console.log(e);
      })
    },

    // fetches a chart's status from the API.
    // if it's a rave chart, we retrieve the {foundation: boolean, advanced: boolean}
    // Otherwise we retrieve {is_unlocked:boolean}
    fetchChartStatus(chartType) {
      if (this.isTempDoc) return;
      this.axios.get(`/content/${this.docId}/status`, {
        params: {
          type: chartType
        }
      }).then(res => {
        this.$store.commit('charts/SET_CHART_STATUS', res.data);
      }).catch(() => {
        this.$store.commit('charts/SET_CHART_STATUS', null);
      });
    },

    save() {
      this.saving = true;
      this.saveTempChart(this.chart).then(res => {
        this.$notify({
          type: 'success',
          title: this.$t('success.title'),
          text: this.$t('success.created')
        });
        this.$router.push('/charts/' + res.chart.id)
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(()=>{
        this.saving = false;
      });
    },

    showJustNowConnection(chart = this.chart){
      this.createTransitWrapper({
        dateStamp: moment(),
        timeStamp: moment(),
        chart: {name: chart.meta.name, ...chart},
      })
    }
  }
}
</script>