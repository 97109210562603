import axios from "axios";
import router from '../../router'
import {getLocalStorageItem, localStorageKeys, setLocalStorageItem, getCookie} from "@/utils/localStorage";
import {config} from "@/utils/config";

const generalOptionsTemplate = () => {
    return {
        inputDateFormat: 'MM/DD/YYYY',
        welcomePage: 0,
        timeFormat: 'AM/PM',
        dateFormatDisplay: 'Do MMMM YYYY',
        libraryOrder: 'createdAt',
        information:{
            properties: 1,
            activations: 1,
            centers: 1,
            channels: 1,
            gates: 1,
            statistics: 0,
            notes: 1,
            tags: 1,
            transitTool: 1
        },
        detailed: {
            multipleActivation: 1,
            closeEmpty: 1,
            emptyOnClose: 1,
            extendedContent: 1,
        },
        transit: {
            shiftSound: -1,
            hexagram: 1,
            channel: 1,
            past: 1,
            future: 1,
            extendedOptions: 1,
            oneTransitPerPlanet: 1,
            dismissNotificationsOlderThan: '6'
        }
    }
}

const state = {
    user: getLocalStorageItem(localStorageKeys.USER) || {},
    isLoggedIn: getLocalStorageItem(localStorageKeys.LOGGED_IN),
    token: getLocalStorageItem(localStorageKeys.TOKEN)  || null,
    generalOptions: getLocalStorageItem('generalOptions') || generalOptionsTemplate(),
    tags: [],
    chartsToken: null,
    unlockedCharts: [],
    subscriptions: [],
    settings: {},
    notifications: {
        data: [],
    }
}

const mutations = {
    SET_USER(state, user){
        state.user = user;
        setLocalStorageItem(localStorageKeys.USER, user, true);
        setLocalStorageItem(localStorageKeys.CHART_ID, user.chart_id);
    },
    SET_IS_LOGGED_IN(state, value){
        state.isLoggedIn = value;
        if(!value) localStorage.removeItem(localStorageKeys.LOGGED_IN);
        else setLocalStorageItem(localStorageKeys.LOGGED_IN, true, true);
    },

    SET_NOTIFICATIONS(state, notifications){
        state.notifications.data = notifications;
    },

    SET_NOTIFICATIONS_LAST_READ(state, id){
        state.notifications.lastRead = id;
        setLocalStorageItem(localStorageKeys.LAST_NOTIFICATION_READ, id);
    },

    SET_CHART_ID(state, chartId) {
        state.user.chart_id = chartId;
        setLocalStorageItem(localStorageKeys.USER, state.user, true);
        setLocalStorageItem(localStorageKeys.CHART_ID, chartId);
    },

    SET_TOKEN(state, token){
        state.token = token;
        setLocalStorageItem(localStorageKeys.TOKEN, token);
    },

    SET_CHARTS_TOKEN(state, token){
        state.chartsToken = token;
    },

    SET_UNLOCKED_CHARTS(state, charts){
        state.unlockedCharts = charts;
    },

    SET_SUBSCRIPTIONS(state, subscriptions) {
        state.subscriptions = subscriptions
    },

    SET_SETTINGS(state, settings){
        state.settings = settings
    },

    ADD_TAGS(state, newTags){
        for(let tag of newTags){
            if (!state.tags.find(x =>x.id === tag.id)) state.tags.push(tag)
            else {
                const index = state.tags.findIndex(x => x.id === tag.id);
                state.tags[index] = tag;
            }
        }
    }
}

const getters = {}

const actions = {

    async init(context){
        await Promise.all([
            context.dispatch('getUserUnlockedCharts'),
            context.dispatch('getUserSubscriptions'),
            context.dispatch('getSettings'),
            context.dispatch('updatePromoter'),
            context.dispatch('getUserCarts')
        ]);
        return context.dispatch('getChartsMicroserviceToken');
    },
    setUser(context, data){
        context.commit('SET_USER', data);
    },
    loginWithoutChartsToken(context, data) {
        context.commit("SET_IS_LOGGED_IN", true);
        context.commit("SET_USER", data.user);
        context.commit('SET_TOKEN', data.token);
    },
    login(context, data) {
        context.commit("SET_IS_LOGGED_IN", true);
        context.commit("SET_USER", data.user);
        context.commit('SET_TOKEN', data.token);

        // special casing if we get the chartsToken in the same request.
        if(data.chartsToken){
            context.commit('SET_CHARTS_TOKEN', data.chartsToken);

            context.dispatch('charts/getUserProfileChart', null, {root:true});

            context.dispatch('getSettings');
            context.dispatch('updatePromoter');

            return context.dispatch('getUserTags');
        }
        else return context.dispatch('getChartsMicroserviceToken'); // get token.
    },
    logout(context){
        context.commit("SET_IS_LOGGED_IN", false);
        context.commit("SET_USER", {});
        context.commit("SET_TOKEN", null);
        context.commit('SET_CHARTS_TOKEN', null);

        return context.dispatch('charts/cleanUp', null, {root: true})
    },

    setChartId(context, chartId){
        context.commit('SET_CHART_ID', chartId);
        return context.dispatch('charts/getUserProfileChart', null, {root: true});
    },

    updateUser(context, val){
        context.commit('SET_USER', {...context.state.user, ...val});
    },

    getChartsMicroserviceToken({state, commit, dispatch}){
        if(!state.token) return; // we can't do this without user's token.

        if(state.chartsToken) return state.chartsToken;

        axios.get(config.apiUrl + '/users/charts-token', {headers: {'x-access-token': state.token}}).then(res=>{

            commit('SET_CHARTS_TOKEN', res.data);

            dispatch('charts/getUserProfileChart', null, {root:true});
            dispatch('getUserTags');

        }).catch(e=>{
            if(e.response.status === 401){
                dispatch("logout").then( () => {
                    router.push({name: 'home'})
                });
            }
        })
    },

    getSettings({state, commit}) {
        if(!state.token) return; // we can't do this without user's token.
        axios.get(config.apiUrl + '/users/settings', {headers: {'x-access-token': state.token}}).then(res=>{
            if(res.data) commit('SET_SETTINGS', res.data);
        }).catch(()=>{});
    },

    updatePromoter({state, commit}) {
        if (!state.token) return;
        const cookie = getCookie('mbg_first_promoter');
        if (!cookie) return;
        if (state.user.promoter) return;

        axios.post(config.apiUrl + '/users/first-promoter', {
            promoterId: cookie,
        }, {
            headers: {'x-access-token': state.token}
        }).then(() => {
            commit('SET_USER', {...state.user, promoter: cookie})
        }).catch(()=>{});
    },

    getUserUnlockedCharts({state, commit}) {
        if(!state.token) return; // we can't do this without user's token.
        return axios.get(config.apiUrl + '/billing/unlocked-charts', {headers: {'x-access-token': state.token}}).then(res=>{
            if(res.data) commit('SET_UNLOCKED_CHARTS', res.data);
        }).catch(()=>{});
    },
    getUserSubscriptions({state, commit}) {
        if(!state.token) return; // we can't do this without user's token.
        return axios.get(config.apiUrl + '/billing/subscriptions', {headers: {'x-access-token': state.token}}).then(res=>{
            if(res.data) commit('SET_SUBSCRIPTIONS', res.data);
        }).catch(()=>{});
    },
    getUserTags({state, commit}) {
        return axios.get(config.chartsUrl + '/api/users/tags', {headers: {'x-charts-token': state.chartsToken}}).then(res => {
            commit('ADD_TAGS', res.data);
        }).catch(() => {});
    },

    getUserCarts({state, dispatch}) {
        if(!state.token) return; // we can't do this without user's token.
        return axios.get(config.apiUrl + '/cart', {headers: {'x-access-token': state.token}}).then(res=> {
            dispatch('checkout/setupFromServer', res.data, { root: true })
        }).catch(()=>{});

    }

}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}